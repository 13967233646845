import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import hbm4eulogo from '../static/images/hbm4eu-logo.png';
import parclogo from '../static/images/PARC logo.png';
import NavBarDropdown from './NavBarDropdown';
// import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

function ResponsiveAppBar() {
    const [modal, setModal] = React.useState(false);
    // const handleModalOpen = () => {
    //     setModal(true);
    // }
    const handleModalClose = () => setModal(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        borderRadius: '10px'
    };

  return (
    <AppBar class="min-w-full border-b-2 border-separate"> 
      <Container class="flex flex-wrap items-center justify-between mx-auto min-w-full">
        <Toolbar class="flex items-center min-w-full" disableGutters>
          <a class="flex items-center " href="https://www.eu-parc.eu/">
          <img class="flex w-80 h-32 object-cover" src={parclogo} alt="PARC-logo"/>
          </a>
          <a href="https://www.hbm4eu.eu/"><img class="w-28" src={hbm4eulogo} alt="HBM4EU-logo"/></a>

          <div class="flex w-full">
          <Box class="ml-auto md:flex md:text-sm md:font-medium ">
              {/* <Button disableRipple
                class="p-2 md:m-2 text-black rounded md:hover:bg-transparent md:hover:border-0"
                key='Help'
                onClick={handleModalOpen}
              >
                <QuestionMarkCircleIcon class="inline-flex w-12 justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 aria-hidden: true"></QuestionMarkCircleIcon>
              </Button> */}
            <NavBarDropdown></NavBarDropdown>
          </Box>
          </div>
            <Modal
                open={modal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {/* <Box sx={style}> */}
                <Box sx={style}>
                    <Typography class="text-2xl font-light" id="modal-modal-title">
                        Need help?
                    </Typography>
                    <Typography class="text-md m-4 font-light" id="modal-modal-description" >
                        If you're experiencing any problems, try refreshing the page and reselecting your file. 
                    </Typography>
                    <Typography class="text-md m-4 font-light" id="modal-modal-description">
                        Make sure you are using the correct format and that you are using a file with extension
                        ".xlsx".
                    </Typography>
                    <Typography class="text-md m-4 font-light" id="modal-modal-description">
                        If none of these steps help, contact us via the contact button, or send us an email at PARC.DATAMANAGEMENT@vito.be
                    </Typography>
                </Box>
            </Modal>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;