function Notfound() {
  return (
    <div>
      <section class="bg-white min-h-screen space-y-24 pt-32 items-center">
        <h1 class="font-bold text-7xl">Oops...</h1>
        <p>The page you are looking for does not exist.</p>
        <div >
        <a class="inline-flex items-center justify-center px-5 py-3 text-base hover:bg-gray-100 font-medium text-center border rounded-lg focus:ring-4 focus:ring-gray-100" href="/">Back to overview</a>
        </div>
      </section>
    </div>
  );
}

export default Notfound;
