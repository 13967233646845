import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import ErrorTable from "../components/ErrorTable";
import { useLoading, Oval } from "@agney/react-loading";
import data_person from "../static/images/data_person.jpg";
import Dropdown from "../components/Dropdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";

function DataValidation() {
  const [data, setData] = useState();
  const [dataparsed, setDataParsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codebooksMappings, setCodebooksMappings] = useState(null);
  const [selected, setSelected] = useState(null);
  const [logOutput, setLogOutput] = useState("");
  async function start_validation() {
    const pyodideWorker = new Worker("/webworker.js");
    try {
      setLoading(true);
      if (document.getElementById("file").files[0] !== null) {
        var file = document.getElementById("file").files[0];
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        let uint8_view;
        reader.onload = () => {
          uint8_view = new Uint8Array(reader.result);
          pyodideWorker.postMessage({ file: uint8_view, id: selected.id });
          pyodideWorker.onmessage = (e) => {
            if (e.data !== undefined && e.data.logs !== undefined) {
              setLogOutput(logOutput + e.data.logs);
            }
            if (
              !(e.data === undefined) ||
              e.data.results.read_errors !== undefined
            ) {
              setData(e.data);
              setDataParsed(true);
              if (e.data.done === 1) {
                document.getElementById("file").value = null;
                document
                  .getElementById("errors")
                  .scrollIntoView({ block: "start", behavior: "smooth" });
                setLoading(false);
              }
            }
          };
        };
      }
    } catch (e) {
      console.log(
        `Error in pyodideWorker at ${e.filename}, Line: ${e.lineno}, ${e.message}`
      );
    }
  }

  const errorSection = dataparsed ? (
    <ErrorTable errors={data}></ErrorTable>
  ) : (
    <div></div>
  );
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Oval width="50" />,
  });
  const loadingSection = loading ? (
    <div
      className="flex flex-col justify-center align-middle mt-12 mb-12 w-full"
      {...containerProps}
    >
      <div className="flex justify-center pt-12">{indicatorEl}</div>
    </div>
  ) : (
    <br />
  );
  const logsSection =
    loading || logOutput !== "" ? (
      <div className="flex justify-center p-auto">
        <SyntaxHighlighter
          class="overflow-y-auto overflow-x-hidden w-2/3 h-96 max-h-96 max-w-3xl"
          language="bash"
          style={materialLight}
          wrapLines
        >
          {logOutput}
        </SyntaxHighlighter>
      </div>
    ) : (
      <></>
    );

  useEffect(() => {
    fetch("data/codebook_mappings.json")
      .then((response) => response.json())
      .then((data) => {
        setCodebooksMappings(data);
        setSelected(data[0]);
      });
  }, []);
  return (
    <div>
      <section class="bg-white min-h-screen">
        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
              Human biomonitoring data validation
            </h1>
            <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
              This tool allows to perform a check of the individual level data,
              prepared in the harmonized data templates, with regard to the
              formats and rules specified in the basic codebook. The tool runs
              locally on your PC, therefore the individual data are not stored
              but stays exclusively on your local PC.
            </p>
            <a
              href="#data_validation_tool"
              class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center border rounded-lg focus:ring-4 focus:ring-gray-100"
            >
              Get started
            </a>
          </div>
          <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={data_person} alt="mockup"></img>
          </div>
        </div>
      </section>
      <section className="flex min-h-screen justify-evenly bg-gray-50">
        <div className="w-2/3 m-auto align-middle p-auto">
          <div id="errors" className="flex justify-center">
            <div>{errorSection}</div>
          </div>
          <div class="grid grid-cols-2 gap-8 align-middle pb-4">
            <div class="place-self-center">
              <label>Select codebook...</label>
              {codebooksMappings && selected && (
                <Dropdown
                  id="dropdown"
                  selectedProject={selected}
                  setSelected={setSelected}
                  codebooks_mappings={codebooksMappings}
                ></Dropdown>
              )}
            </div>
            <span class="anchor" id="data_validation_tool"></span>
            <div class="pt-8">
              {
                <Button variant="outlined" component="label" disabled={loading}>
                  Select file
                  <input
                    type="file"
                    onInput={start_validation}
                    id="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                  />
                </Button>
              }
            </div>
          </div>
          {loadingSection}
          {logsSection}
        </div>
      </section>
    </div>
  );
}

export default DataValidation;
