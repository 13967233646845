import developer from "../static/images/developer.jpg";
function UnderDevelopment() {
  return (
    <div>
      <section class="bg-white min-h-screen space-y-12 pt-16 items-center">
        <h1 class="font-bold text-6xl">Under development</h1>
        <p>
          We are working hard to make this tool available, please check back
          later!
        </p>
        <div class="flex-col">
          <a
            class="inline-flex items-center px-5 py-3 text-base hover:bg-gray-100 font-medium text-center border rounded-lg focus:ring-4 focus:ring-gray-100"
            href="/"
          >
            Back to overview
          </a>
        </div>
        <div>
          <img
            class="inline-flex items-center w-1/3"
            src={developer}
            alt="developer"
          ></img>
        </div>
      </section>
    </div>
  );
}

export default UnderDevelopment;
