import "./App.css";
import NavBar from "./components/NavBar";
import { Routes, Route } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import DataValidation from "./pages/DataValidation";
import DerivedVariables from "./pages/DerivedVariables";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Harmonization from "./pages/Harmonization";
import DescriptiveStatistics from "./pages/DescriptiveStatistics";
import UnderDevelopment from "./pages/UnderDevelopment";
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light: "#6ab7ff",
        main: "#2196f3",
        // main: '#1e88e5',
        dark: "#005cb2",
        contrastText: "#fff",
      },
      secondary: {
        light: "#efefef",
        main: "#bdbdbd",
        dark: "#8d8d8d",
        contrastText: "#000",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <NavBar></NavBar>
        <main className="App-main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/harmonization" element={<Harmonization />} />
            <Route path="/validation" element={<DataValidation />} />
            <Route path="/derivedvariables" element={<UnderDevelopment />} />
            <Route path="/summarystats" element={<UnderDevelopment />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <footer className="text-xs pt-96 bg-gray-50 flex-inline justify-center">
          <div class="text-lg italic font-light p-4">
            <p>
              The webpage and its content have been developed within the context
              of H2020 project HBM4EU (2017-2022; Grant Agreement No 733032) and
              Horizon Europe partnership PARC (2022-2029; Grant Agreement No
              101057014).
            </p>
          </div>
          <div class="text-sm font-light p-4">
            <p>
              This site is under active development. Please send any feedback or
              problems to parc.datamanagement@vito.be
            </p>
          </div>
          <div class="font-light p-4">
            <a href="https://www.freepik.com/author/stories">
              Images by storyset
            </a>{" "}
            on Freepik
          </div>
        </footer>
      </div>
    </ThemeProvider>
  );
}

export default App;
