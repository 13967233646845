import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";

export default function Row(props) {
  const [open, setOpen] = React.useState(false);
  console.log(props);
  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={() => setOpen(!open)}
        tabIndex={-1}
        key={props.row.name}
      >
        <TableCell component="td" width="5%">
          {props.row.level === "CRITICAL" && (
            <CancelIcon
              style={{ color: "red" }}
              inputProps={{
                "aria-labelledby": props.row.labelId,
              }}
            />
          )}
          {props.row.level === "ERROR" && (
            <ErrorIcon
              style={{ color: "orange" }}
              inputProps={{
                "aria-labelledby": props.row.labelId,
              }}
            />
          )}
          {props.row.level === "WARNING" && (
            <WarningIcon
              style={{ color: "gold" }}
              inputProps={{
                "aria-labelledby": props.labelId,
              }}
            />
          )}
        </TableCell>
        <TableCell width="35%" component="td" id={props.labelId}>
          {props.row.message}
        </TableCell>
        <TableCell width="10%" component="td" align="left">
          {props.row.name}
        </TableCell>
        <TableCell width="20%" component="td" align="left">
          {props.row.sheet}
        </TableCell>
        <TableCell width="30%" component="td" align="left">
          {props.row.ids == null ? (
            <></>
          ) : props.row.ids.length > 10 ? (
            `${props.row.ids.length} affected values`
          ) : (
            props.row.ids.join(", ")
          )}
        </TableCell>
      </TableRow>
      <TableRow sx={{ bgcolor: "secondary.light" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>Excel rows</TableCell>
                    <TableCell>Values</TableCell>
                    <TableCell>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell width="40%" component="td" align="left">
                      {props.row.ids &&
                        props.row.ids.filter(Boolean).join(", ")}
                    </TableCell>
                    <TableCell width="40%" component="td" align="left">
                      {props.row.vals &&
                        props.row.vals.filter(Boolean).join(", ")}
                    </TableCell>
                    <TableCell width="20%" component="td" align="left">
                      {props.row.details && props.row.details}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
