import data_person from "../static/images/data_person.jpg";
import summary_statistics from "../static/images/summary_statistics.jpg";
import derived_variables from "../static/images/derived_variables.png";
import data_harmonization from "../static/images/data_harmonization.jpg";
import HomeCard from "../components/HomeCard";
function Home() {
  return (
    <div>
      <section class="bg-gray-50 min-h-screen">
        <div class="grid grid-cols-1 max-w-screen-lg px-4 py-8 mx-auto lg:gap-12 xl:gap-12 lg:py-16 lg:grid-cols-12">
            <div class="flex align-middle justify-center lg:col-span-6">
            <HomeCard
              img={data_harmonization}
              title="HBM data harmonization"
              description="Instructions for harmonization of human biomonitoring datasets."
              button_text="Take a look"
              link="/harmonization"
            ></HomeCard>
            </div>
            <div class="flex align-middle justify-center lg:col-span-6">
            <HomeCard
              img={data_person}
              title="HBM data validation"
              description="In-browser validation of human biomonitoring datasets, without sending the data to a server."
              button_text="Take a look"
              link="/validation"
            ></HomeCard>
            </div>
            <div class="flex align-middle justify-center lg:col-span-6">
            <HomeCard
              img={derived_variables}
              title="HBM derived variables"
              description="Extend your data with derived variables, such as medium bound imputations."
              button_text="Take a look"
              link="/derivedvariables"
            ></HomeCard>
            </div>
            <div class="flex align-middle justify-center lg:col-span-6">
            <HomeCard
              img={summary_statistics}
              title="HBM summary statistics"
              description="Calculate fit-for-purpose summary statistics for your HBM datasets."
              button_text="Take a look"
              link="/summarystats"
            ></HomeCard>
            </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
